@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
$light: #fff;
$dark: #000;
$l_warning: red;
$danger: $error;
$grey: $un_gray;
.search_warp {
	display: flex;
	align-items: center;
	padding-inline-start: 20px;
	font-weight: 200;
	position: relative;

	.input_block {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		.top__middle__search__input {
			border: none;
			outline: none;
			padding: 5px 20px;
			font-size: 16px;
			color: getColor(grey600);
			background-color: getColor(white);
			height: 44px;
			width: 500px;
			border: 1px solid color(grey300);
			border-radius: 6px;
			&:focus {
				// Show search_results when this input is focused
				& ~ .search_results {
					opacity: 1;
					visibility: visible;
				}
				& ~ .overlay {
					display: block;
				}
			}

			&::placeholder {
				font-size: 16px;
				color: #8888;
			}
		}

		.top__middle__search__button {
			position: absolute;
			width: 45px;
			height: 44px;
			min-width: 45px;
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			right: 0;
			color: getColor(grey500);
		}
	}
}



@media (min-width: 576px) {
	.search_warp {
		padding-inline-start: 0px;
	}
}
@media (min-width: 1024px) {
	.search_warp {
		padding-inline-start: 0px;
	}
}

@media (min-width: 1100px) {
	.search_warp {
		padding-inline-start: 0px;
	
	}
}

@media (min-width: 1200px) {
	.search_warp {
		padding-inline-start: 0px;
	}
}
@media (min-width: 1280px) {
	.search_warp {
		padding-inline-start: 0px;
	}
}

