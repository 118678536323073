// components/CategoryTabs.module.scss
@import '@/services/scss/variables.scss';

.container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 20px;
	background-color: white;
	padding: 8px;
	width: fit-content;
	border-radius: 12px;
	justify-content: center;
	align-items: center;
	border: 1px solid getColor(grey300);
}


.viewAll,
.tab {
	padding: 10px 20px;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	background-color: #fff;
	font-size: 16px;
	font-weight: 500;
	line-height: 150%;

	&:hover {
		background-color: #f0f0f0;
	}
}

.viewAll {
	background-color: getColor(primary);
	color: white;

	&:hover {
		background-color: #45a049;
	}
}

.tab.active {
	background-color: getColor(primary);
	color: white;
}

//features/FeatureCard.module.scss

.featured_blog {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin-block: 30px;
	border: 1px solid rgba(17, 17, 17, 0.1);
	border-radius: 10px;
	box-shadow: none;
	height: 80%;
	// height: 450px;
}
.featured_blog__img {
	grid-column: 1/2;
	position: relative;
	padding-top: 50%;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	& > img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		aspect-ratio: 1;
	}
}

.blog_content {
	padding: 2rem;
}

.blog_tag_wrapper {
	display: flex;
	gap: 0.75rem;
	align-items: center;
	text-transform: capitalize;
}

.blog_tag {
	display: inline-flex;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	background-color: #1f2937;
	color: #e5e7eb;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 500;
	align-items: center;
	border-radius: 0.375rem;
	gap: 0.375rem;
}

.blog_tag_reading_time {
	color: #112950;
}

.blog_readMore {
	display: inline-flex;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	align-items: center;
	border-radius: 0.375rem;
	flex-shrink: 0;
	font-weight: 700;
	color: #ffffff;
	background: #178069;
}

.arrow_icon {
	width: 0.5em;
}

.blog_card {
	border-radius: 1.5rem;
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
}

.blog_card_featured_btn {
	text-transform: capitalize;
	background-color: #178069;
	box-shadow: none;

	&:hover {
		background-color: #0e5c4b;
		box-shadow: none;
	}
}

.blog_card_outline_btn {
	padding: 5px 15px;
	text-transform: capitalize;
	font-weight: semibold;
	border-radius: 0.375rem;

	&:hover {
		background-color: #178069;
		color: #fff;
	}
}

.blog_post_list {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 2rem;
	margin-bottom: 4rem;


}

.blog_post_list__img {
	position: relative;
	border-radius: 10px 10px 0 0;
	height: 200px;
	img {
		height: 200px;
		width: 100%;
		object-fit: cover;
		border-radius: 10px 10px 0 0;
	}
}

.pagination_btn {
	padding: 0.5rem 1rem;
	border-radius: 0.375rem;
	background-color: #ffffff;
	color: #112950;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.25rem;
	border: 1px solid #e5e7eb;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: none;
	cursor: pointer;
	&:hover {
		background-color: #f0f0f0;
	}
	&:disabled {
		background-color: #e5e7eb;
		color: #ffffff;
	}
	&:active {
		background-color: #e5e7eb;
		color: #ffffff;
	}
}
